var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "fill-height" },
    [
      _c(
        "asyent-card",
        _vm._b(
          {
            staticClass: "fill-height",
            attrs: { title: _vm.title },
            scopedSlots: _vm._u(
              [
                !_vm.readonly && !_vm.isDisabledFunc(_vm.name) && !_vm.noNew
                  ? {
                      key: "toolbar-actions",
                      fn: function () {
                        return [
                          _c("asyent-button", {
                            attrs: {
                              id: "v-step-address",
                              "button-icon": "mdi-plus",
                              "button-tooltip": _vm.ae$lng("Add an address"),
                              icon: _vm.$vuetify.breakpoint.smAndDown,
                              text: !_vm.$vuetify.breakpoint.smAndDown,
                            },
                            on: { confirmed: _vm.openForNew },
                          }),
                        ]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "asyent-card",
          _vm.$attrs,
          false
        ),
        [
          _c(
            "v-row",
            { attrs: { "align-content": "space-around" } },
            [
              _vm.hasRecords
                ? _vm._l(_vm.value2, function (item, i) {
                    return _c(
                      "v-col",
                      { key: i, attrs: { cols: "12", md: "6" } },
                      [
                        _c(
                          "v-card",
                          {
                            staticClass: "mx-auto fill-height",
                            attrs: { outlined: "", tile: "" },
                          },
                          [
                            _c("v-card-text", [
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "text-subtitle-1 font-weight-bold text-capitalize mb-1",
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(item["addressName"]) + " "
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "font-italic text-lowercase text-caption",
                                    },
                                    [
                                      _vm._v(
                                        "(" + _vm._s(item["addressType"]) + ")"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "text-subtitle-2 text-capitalize font-italic",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item["addressLine1"]) +
                                      ", " +
                                      _vm._s(item["addressLine2"]) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "h6",
                                {
                                  staticClass:
                                    "text-subtitle-2 text-capitalize font-italic",
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(item["city"]) +
                                      ", " +
                                      _vm._s(item["stateOrProvinceOrRegion"]) +
                                      ", " +
                                      _vm._s(item["country"]) +
                                      " "
                                  ),
                                ]
                              ),
                              item["zipOrPostalCode"] &&
                              item["zipOrPostalCode"].length > 0
                                ? _c(
                                    "h6",
                                    {
                                      staticClass:
                                        "text-subtitle-2 text-capitalize font-italic",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(item["zipOrPostalCode"]) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]),
                            !_vm.readonly && !_vm.isDisabledFunc(_vm.name)
                              ? _c(
                                  "v-card-actions",
                                  [
                                    _c("asyent-button", {
                                      attrs: {
                                        "button-icon": "mdi-pencil",
                                        "button-tooltip": _vm.ae$lng("Edit"),
                                        icon: "",
                                      },
                                      on: {
                                        confirmed: function ($event) {
                                          return _vm.openForEdit(i)
                                        },
                                      },
                                    }),
                                    !_vm.noDelete && !_vm.noDeleteInTable
                                      ? _c("asyent-button", {
                                          attrs: {
                                            "button-icon": "mdi-delete",
                                            "button-tooltip":
                                              _vm.ae$lng("Delete"),
                                            icon: "",
                                            "requires-confirmation": "",
                                          },
                                          on: {
                                            confirmed: function ($event) {
                                              return _vm.deleteSelected(i)
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  })
                : [
                    _c("v-col", { attrs: { cols: "12" } }, [
                      _c("div", { staticClass: "text-caption font-italic" }, [
                        _vm._v(_vm._s(_vm.ae$lng("No data"))),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
          _c("br"),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            fullscreen: _vm.$vuetify.breakpoint.smAndDown,
            persistent: "",
            scrollable: "",
            transition: "dialog-bottom-transition",
            width: "700px",
          },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-toolbar",
                { attrs: { color: "primary", dark: "", "max-height": "56" } },
                [
                  _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
                  _c("v-spacer"),
                  !_vm.isNew && !_vm.noReset
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-reload",
                          "button-tooltip": _vm.ae$lng("Reset"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.resetItem },
                      })
                    : _vm._e(),
                  !_vm.isNew && !_vm.noDelete
                    ? _c("asyent-button", {
                        attrs: {
                          "button-icon": "mdi-delete",
                          "button-tooltip": _vm.ae$lng("Delete"),
                          icon: "",
                          "requires-confirmation": "",
                        },
                        on: { confirmed: _vm.deleteItem },
                      })
                    : _vm._e(),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-check",
                      "button-tooltip": _vm.ae$lng("Save"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.saveItem },
                  }),
                  _c("asyent-button", {
                    attrs: {
                      "button-icon": "mdi-close",
                      "button-tooltip": _vm.ae$lng("Cancel"),
                      icon: "",
                      "requires-confirmation": "",
                    },
                    on: { confirmed: _vm.closeDialog },
                  }),
                ],
                1
              ),
              _c(
                "v-card-text",
                {
                  staticClass: "ma-0 pa-2",
                  style: {
                    "max-height": _vm.$vuetify.breakpoint.smAndUp
                      ? "500px"
                      : "",
                  },
                },
                [
                  _c(
                    "ValidationObserver",
                    { ref: _vm.internalFormReference, attrs: { disabled: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.preventDefault()
                            },
                          },
                          model: {
                            value: _vm.formValid,
                            callback: function ($$v) {
                              _vm.formValid = $$v
                            },
                            expression: "formValid",
                          },
                        },
                        [
                          _c(
                            "v-container",
                            { attrs: { fluid: "" } },
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c("asyent-form-select", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          "items-reload": _vm.addressTypeReload,
                                          props: {},
                                          "drop-down": "",
                                          label: _vm.ae$lng("Address Type"),
                                          mandatory: "",
                                          name: "addresses.addressType",
                                          sortable: "",
                                        },
                                        model: {
                                          value: _vm.itemData["addressType"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "addressType",
                                              $$v
                                            )
                                          },
                                          expression: "itemData['addressType']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("Name/Business"),
                                          name: "addresses.addressName",
                                        },
                                        model: {
                                          value: _vm.itemData["addressName"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "addressName",
                                              $$v
                                            )
                                          },
                                          expression: "itemData['addressName']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("Address Line #1"),
                                          name: "addresses.addressLine1",
                                        },
                                        model: {
                                          value: _vm.itemData["addressLine1"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "addressLine1",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemData['addressLine1']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("Address Line #2"),
                                          name: "addresses.addressLine2",
                                        },
                                        model: {
                                          value: _vm.itemData["addressLine2"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "addressLine2",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemData['addressLine2']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("City"),
                                          name: "addresses.city",
                                        },
                                        model: {
                                          value: _vm.itemData["city"],
                                          callback: function ($$v) {
                                            _vm.$set(_vm.itemData, "city", $$v)
                                          },
                                          expression: "itemData['city']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng(
                                            "State, Province, or Region"
                                          ),
                                          name: "addresses.stateOrProvinceOrRegion",
                                        },
                                        model: {
                                          value:
                                            _vm.itemData[
                                              "stateOrProvinceOrRegion"
                                            ],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "stateOrProvinceOrRegion",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemData['stateOrProvinceOrRegion']",
                                        },
                                      }),
                                      _c("asyent-form-text", {
                                        attrs: {
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          props: {},
                                          label: _vm.ae$lng("Postal Code"),
                                          name: "addresses.zipOrPostalCode",
                                        },
                                        model: {
                                          value:
                                            _vm.itemData["zipOrPostalCode"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "zipOrPostalCode",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "itemData['zipOrPostalCode']",
                                        },
                                      }),
                                      _c("asyent-form-catalog", {
                                        attrs: {
                                          sortable: "",
                                          "form-errors": _vm.formErrors,
                                          "form-rules": _vm.formFieldsRules,
                                          "is-disabled-func": _vm.isDisabled,
                                          "service-name": "catalog",
                                          catalog: "CatalogConnectIdCountry",
                                          props: {},
                                          "show-item-avatar": "",
                                          label: _vm.ae$lng("Country"),
                                          "item-value": "code",
                                          "item-text": "name",
                                          name: "addresses.country",
                                        },
                                        model: {
                                          value: _vm.itemData["country"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.itemData,
                                              "country",
                                              $$v
                                            )
                                          },
                                          expression: "itemData['country']",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }